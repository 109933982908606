import React from 'react';
import PropTypes from 'prop-types';

BgOrgHead.propTypes = {
  className: PropTypes.string
}

export default function BgOrgHead(props) {
  return (
    <>
    { props.className === "desktop-only" ?
      <svg width="658" height="874" viewBox="0 0 658 874" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <g clipPath="url(#clip0)">
          <path d="M179.177 871.605C418.993 871.605 613.402 677.026 613.402 437C613.402 196.974 418.993 2.39453 179.177 2.39453C-60.6388 2.39453 -255.048 196.974 -255.048 437C-255.048 677.026 -60.6388 871.605 179.177 871.605Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
          <path opacity="0.2" d="M179.476 812.82C386.854 812.82 554.967 644.56 554.967 437C554.967 229.44 386.854 61.18 179.476 61.18C-27.9018 61.18 -196.015 229.44 -196.015 437C-196.015 644.56 -27.9018 812.82 179.476 812.82Z" stroke="var(--cl-secondary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
          <path opacity="0.2" d="M179.476 751.64C353.095 751.64 493.841 610.771 493.841 437C493.841 263.229 353.095 122.36 179.476 122.36C5.85739 122.36 -134.888 263.229 -134.888 437C-134.888 610.771 5.85739 751.64 179.476 751.64Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
          <path opacity="0.1" d="M292.001 248H-232V545.89H292.001V248Z" fill="var(--cl-primary)"/>
          <path d="M-231 529.307V283.762H278.428V529.307H-231Z" fill="white" stroke="#388E3C" strokeWidth="2"/>
          <path d="M-231.086 282.262V245.5H279.342V282.262H-231.086Z" fill="#388E3C" stroke="#388E3C"/>
          <path opacity="0.1" d="M238.901 360.38H44.6719V372.963H238.901V360.38Z" fill="var(--cl-footer)"/>
          <path opacity="0.1" d="M238.901 413.526H44.6719V426.109H238.901V413.526Z" fill="var(--cl-footer)"/>
          <path d="M213.756 440.094H135.5V452.677H213.756V440.094Z" fill="var(--cl-primary)"/>
          <path d="M608.257 132.859H331.764V263.588H608.257V132.859Z" fill="#E5F2FF"/>
          <path d="M598.776 137.972H340.932V258.321H598.776V137.972Z" fill="white"/>
          <path opacity="0.3" d="M530.412 164.288H380V178.571H530.412V164.288Z" fill="var(--cl-footer)"/>
          <path d="M458.005 196.973H380V211.257H458.005V196.973Z" fill="var(--cl-footer)"/>
          <path opacity="0.2" d="M658 667.401H56.5088V829.33H658V667.401Z" fill="var(--cl-primary)"/>
          <path opacity="0.1" d="M629.781 632.07H19.5339V804.487H629.781V632.07Z" fill="var(--cl-primary)"/>
          <path d="M27.912 638.912H621.403C623.06 638.912 624.403 640.255 624.403 641.912V794.644C624.403 796.301 623.06 797.644 621.403 797.644H27.9121C26.2552 797.644 24.912 796.301 24.912 794.644V641.912C24.912 640.255 26.2552 638.912 27.912 638.912Z" fill="white" stroke="var(--cl-primary)" strokeWidth="2"/>
          <path d="M170.903 778.507C204.133 778.507 231.072 751.544 231.072 718.284C231.072 685.025 204.133 658.062 170.903 658.062C137.672 658.062 110.733 685.025 110.733 718.284C110.733 751.544 137.672 778.507 170.903 778.507Z" fill="url(#paint0_linear)"/>
          <path d="M170.902 771.814C200.44 771.814 224.385 747.848 224.385 718.284C224.385 688.721 200.44 664.755 170.902 664.755C141.365 664.755 117.42 688.721 117.42 718.284C117.42 747.848 141.365 771.814 170.902 771.814Z" fill="var(--cl-primary)"/>
          <g opacity="0.2">
            <path opacity="0.5" d="M371.459 678.14H276.193V694.866H371.459V678.14Z" fill="var(--cl-secondary)"/>
            <path opacity="0.5" d="M538.582 709.915H276.193V726.641H538.582V709.915Z" fill="var(--cl-secondary)"/>
            <path opacity="0.5" d="M538.582 741.703H276.193V758.428H538.582V741.703Z" fill="var(--cl-secondary)"/>
          </g>
          <path d="M205.999 696.817L194.253 687.85L163.295 728.449L146.021 715.255L137.062 727L160.22 744.696L160.28 744.612L166.142 749.09L205.999 696.817Z" fill="white"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear" x1="60259.8" y1="78975.8" x2="60259.8" y2="66859.1" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" stopOpacity="0.25"/>
            <stop offset="0.54" stopColor="#808080" stopOpacity="0.12"/>
            <stop offset="1" stopColor="#808080" stopOpacity="0.1"/>
          </linearGradient>
          <clipPath id="clip0">
            <rect width="1085" height="874" fill="white" transform="translate(-427)"/>
          </clipPath>
        </defs>
      </svg>
      :
      <svg width="367" height="153" viewBox="0 0 367 153" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <path d="M179.692 3.65817C276.838 3.65817 355.59 82.6091 355.59 180C355.59 277.391 276.838 356.342 179.692 356.342C82.5469 356.342 3.79492 277.391 3.79492 180C3.79492 82.6091 82.5469 3.65817 179.692 3.65817Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
        <path opacity="0.2" d="M179.813 27.5104C263.819 27.5104 331.919 95.7823 331.919 180C331.919 264.218 263.819 332.49 179.813 332.49C95.8079 332.49 27.708 264.218 27.708 180C27.708 95.7823 95.8079 27.5104 179.813 27.5104Z" stroke="var(--cl-secondary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
        <path opacity="0.2" d="M179.813 52.3343C250.143 52.3343 307.157 109.492 307.157 180C307.157 250.508 250.143 307.666 179.813 307.666C109.483 307.666 52.4692 250.508 52.4692 180C52.4692 109.492 109.483 52.3343 179.813 52.3343Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
        <path opacity="0.1" d="M225.396 256.687H13.1313V135.818H225.396V256.687Z" fill="var(--cl-primary)"/>
        <path d="M13.7988 156.962V142.641H219.97V156.962H13.7988Z" fill="#388E3C" stroke="#388E3C"/>
        <path opacity="0.2" d="M373.656 86.5146H130.001V20.8114H373.656V86.5146Z" fill="var(--cl-primary)"/>
        <path opacity="0.1" d="M362.225 100.85H115.024V30.8915H362.225V100.85Z" fill="var(--cl-primary)"/>
        <path d="M120.797 97.4795H356.451C358.108 97.4795 359.451 96.1364 359.451 94.4795V37.2622C359.451 35.6054 358.108 34.2622 356.451 34.2622H120.797C119.14 34.2622 117.797 35.6054 117.797 37.2622V94.4795C117.797 96.1364 119.14 97.4795 120.797 97.4795Z" fill="white" stroke="var(--cl-primary)" strokeWidth="2"/>
        <path d="M176.341 41.4332C189.802 41.4332 200.714 52.3732 200.714 65.8684C200.714 79.3637 189.802 90.3037 176.341 90.3037C162.879 90.3037 151.967 79.3637 151.967 65.8684C151.967 52.3732 162.879 41.4332 176.341 41.4332Z" fill="url(#paint0_linear)"/>
        <path d="M176.34 44.1488C188.306 44.1488 198.005 53.873 198.005 65.8685C198.005 77.8639 188.306 87.5882 176.34 87.5882C164.375 87.5882 154.675 77.8639 154.675 65.8685C154.675 53.873 164.375 44.1488 176.34 44.1488Z" fill="var(--cl-primary)"/>
        <g opacity="0.2">
          <path opacity="0.5" d="M257.583 82.157H218.992V75.3705H257.583V82.157Z" fill="var(--cl-secondary)"/>
          <path opacity="0.5" d="M325.282 69.2641H218.992V62.4776H325.282V69.2641Z" fill="var(--cl-secondary)"/>
          <path opacity="0.5" d="M325.282 56.3664H218.992V49.5799H325.282V56.3664Z" fill="var(--cl-secondary)"/>
        </g>
        <path d="M190.558 57.0076L185.799 53.3691L173.259 69.8422L166.262 64.4888L162.632 69.2544L172.014 76.4344L172.038 76.4004L174.412 78.2173L190.558 57.0076Z" fill="white"/>
        <defs>
          <linearGradient id="paint0_linear" x1="24517.4" y1="-31687.3" x2="24517.4" y2="-26770.9" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" stopOpacity="0.25"/>
            <stop offset="0.54" stopColor="#808080" stopOpacity="0.12"/>
            <stop offset="1" stopColor="#808080" stopOpacity="0.1"/>
          </linearGradient>
        </defs>
      </svg>

    }
  </>
  )
}