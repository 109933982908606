import React from "react";
import PropTypes from 'prop-types'
import { withRouter } from "next/router";
import Layout from '../layouts/SiteLayout';
import SiteLoader from '../components/SiteLoader';
import validator from '../actions/validation';
import AuthAction from '../services/auth';
import Cookie from '../actions/cookie';
import AuthCheck from '../actions/authCheck';
import SlackWebHook from '../actions/slack';
import map from 'lodash/map';
import intersectionBy from 'lodash/intersectionBy';
import intersection from 'lodash/intersection';
import { error } from "../components/Toast";
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward';
import { MdRemoveRedEye } from '@react-icons/all-files/md/MdRemoveRedEye';
import BgOrgHead from "../components/custom/svg/bgOrgHead";
import BgTeacher from "../components/custom/svg/bgTeacher";
import BgParent from "../components/custom/svg/bgParent";
import BgAgent from "../components/custom/svg/bgAgent";
import Link from 'next/link'


/**** Image Wrappper *********/

ImageWrapper.propTypes = {
  query: PropTypes.object
}

function ImageWrapper(props) {
  return (
    <div className="img-left--wrapper">
      {   !props.query.hasOwnProperty('as') &&
      <>
        <BgOrgHead className="desktop-only" />
        <BgOrgHead className="mobile-only"/>
      </>
      }
      {   props.query.hasOwnProperty('as') &&
      props.query.as !== "partner" &&
      props.query.as !== "agent" &&
      props.query.as !== "parent" &&
      props.query.as !== "teacher" &&
      props.query.as !== "" &&
      <>
        <BgOrgHead className="desktop-only" />
        <BgOrgHead className="mobile-only"/>
      </>
      }
      {   props.query.hasOwnProperty('as') &&
      (props.query.as === "" || props.query.as === undefined) &&
      <>
        <BgOrgHead className="desktop-only" />
        <BgOrgHead className="mobile-only"/>

      </>
      }
      {   props.query.as == 'partner' &&
      <>
        <BgOrgHead className="desktop-only" />
        <BgOrgHead className="mobile-only"/>
      </>

      }
      {   props.query.as == 'parent' &&
      <>
      <BgParent className="desktop-only" />
      <BgParent className="mobile-only"/>
      </>
      }
      {   props.query.as == 'agent' &&
      <>
      <BgAgent className="desktop-only"/>
      <BgAgent className="mobile-only"/>
      </>

      }
      {   props.query.as == 'teacher' &&
      <>
      <BgTeacher className="desktop-only"/>
      <BgTeacher className="mobile-only"/>
      </>
      }

    </div>
  );
}

/**** Sign In Heading Wrappper *********/
LoginHeadeing.propTypes = {
  query: PropTypes.object
}

function LoginHeadeing(props) {
  return (
    <div>
      {   !props.query.hasOwnProperty('as') &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In
        </div>
      </>
      }
      {   props.query.hasOwnProperty('as') &&
      props.query.as !== "partner" &&
      props.query.as !== "agent" &&
      props.query.as !== "parent" &&
      props.query.as !== "teacher" &&
      props.query.as !== "" &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In
        </div>
      </>
      }
      {   props.query.hasOwnProperty('as') &&
      (props.query.as === "" || props.query.as === undefined) &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In
        </div>
      </>
      }
      {   props.query.as == 'partner' &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In as Organization Head
        </div>
      </>

      }
      {   props.query.as == 'parent' &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In as   {process.env.NEXT_PUBLIC_BRAND_NAME} Parent
        </div>
      </>
      }
      {   props.query.as == 'agent' &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In as   {process.env.NEXT_PUBLIC_BRAND_NAME} Agent
        </div>
      </>

      }
      {   props.query.as == 'teacher' &&
      <>
        <div className="signin-as-text txt-32--title">
          Sign In as  {process.env.NEXT_PUBLIC_BRAND_NAME} Teacher
        </div>
      </>
      }

    </div>
  );
}

/**** Sign up redireaction Wrappper *********/

GoToSignUp.propTypes = {
  query: PropTypes.object
}

function GoToSignUp(props) {
  return (
    <>
      {   !props.query.hasOwnProperty('as') &&
      <>
        <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup" ><a>Get an account.</a></Link></h5>
      </>
      }
      {   props.query.hasOwnProperty('as') &&
      props.query.as !== "partner" &&
      props.query.as !== "agent" &&
      props.query.as !== "parent" &&
      props.query.as !== "teacher" &&
      props.query.as !== "" &&
      <>
        <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup" ><a>Get an account.</a></Link></h5>
      </>
      }
      {   props.query.hasOwnProperty('as') &&
      (props.query.as === "" || props.query.as === undefined) &&
      <>
        <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup" ><a>Get an account.</a></Link></h5>
      </>
      }
      {   props.query.as == 'partner' &&
      <>
        <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup?as=orgHead" ><a>Get an account.</a></Link></h5>
      </>

      }
      {   props.query.as == 'parent' &&
      <>
        <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup?as=parent" ><a>Get an account.</a></Link></h5>
      </>
      }
      {   props.query.as == 'agent' &&
      <>
        <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup?as=agent" ><a>Get an account.</a></Link></h5>
      </>

      }
      {   props.query.as == 'teacher' &&
      <>
      <h5 className="sample-line">Don&apos;t have an account yet? <Link href="/signup?as=teacher" ><a>Get an account.</a></Link></h5>
      </>
      }
    </>
  );
}

const roles = [{roleMachineName: 'partner'}, {roleMachineName: 'teacher'}, {roleMachineName: 'agent'}, {roleMachineName: 'parent'}];

class Login extends React.Component {

  static getInitialProps({query, pathname, asPath}) {
    return {query, pathname, asPath};
  }

  constructor (props) {
    super(props);
    this.state = {
      formInput: {
        loginEmail: '',
        loginPassword: '',
      },
      validationTest: false,
      siteLoading: true,
      submitLoading: false
    };
  }

  /***
   *
   * handelLogin will fire when login button will be clicked
   * This function will check whether the user is valid or not and
   * If valid then set token and accessRole will set into cookie
   * Afterthat it will redirect to dashboard
   *
   * Author; Debajit Basu
   * Date: 4th sep,2019
   *
   * @param: event
   * @return:
   *
   */
  handelLogin = (e) => {
    const parentThis = this;
    e.preventDefault();
    this.setState({
      ...this.state,
      validationTest: true,
      submitLoading: true
    }, () => {
      if( validator.email(this.state.formInput.loginEmail.toLowerCase().trim()) &&
        validator.passwordStrength(this.state.formInput.loginPassword, 'week') ){

        AuthAction.loginUser(this.state.formInput.loginEmail, this.state.formInput.loginPassword,'frontend')
          .then( res => {
            const response = res.data.loginUser;

            if (response.code === '200' && response.status === 'success') {
              let token = response.data.accessToken;
              Cookie.setCookie('_accessToken',token,30);
              let role = AuthCheck.checkAuth(token);
              let roleDetails = intersectionBy(Object.values(JSON.parse(role.roleDetails)), roles, 'roleMachineName');
              if (roleDetails.length === 1 && (parseInt(process.env.NEXT_PUBLIC_ORGANIZATION_ID) === undefined || !parseInt(process.env.NEXT_PUBLIC_ORGANIZATION_ID) || parseInt(process.env.NEXT_PUBLIC_ORGANIZATION_ID) === parseInt(role.orgId) || role.orgId === null)) {
                Cookie.setCookie('_accessRole',btoa(parseInt(roleDetails[0].roleId)),30);
                if(roleDetails[0].roleMachineName === "agent") {
                  this.props.router.push('/agent');
                }else {
                  this.props.router.push('/students');
                }
              } else {
                parentThis.setState({...parentThis.state, submitLoading: false}, () => {
                  error("You don't have access to this platform");
                });
              }
            }else{
              this.setState({...this.state, submitLoading: false}, () => {
                this.clearFormValue();
                error(response.message);
              });
            }
          }).catch(function(err){
          parentThis.setState({...parentThis.state, submitLoading: false});
          parentThis.clearFormValue();
          SlackWebHook.sendSlackMessage('Login','handleLogin',`${err.message}`)
        })

      }else{
        parentThis.setState({...parentThis.state, submitLoading: false}, () => {
          parentThis.clearFormValue();
          error("Missing required fields");
        });
      }
    });
  }

  /***
   *
   * getFormValue is used to get value from form
   * while typing individual key
   * and set it to state for farther use
   *
   * Author; Debajit Basu
   * Date: 4th sep,2019
   *
   * @param: event
   * @return:
   *
   */
  getFormValue = (e) => {
    this.setState({
      ...this.state,
      formInput: {
        ...this.state.formInput,
        [e.target.name] : e.target.value
      },
      validationTest: false
    })
  }

  /***
   *
   * clearFormValue is used to clear value from state
   * and set the state to blank
   *
   * Author; Debajit Basu
   * Date: 4th sep,2019
   *
   * @param:
   * @return:
   *
   */
  clearFormValue = () => {
    this.setState({
      ...this.state,
      formInput: {
        ...this.state.formInput,
        loginPassword: '',
      }
    });
  }

  componentDidMount() {
    if(!AuthCheck.checkCookie()){
      this.setState({
        ...this.state,
        siteLoading: false
      });
    } else {
      const role = AuthCheck.checkAuth(Cookie.getCookie('_accessToken'));
      role.roles = map(Object.values(JSON.parse(role.roleDetails)), 'roleMachineName').join(',');
      if (intersection(['agent'], role.roles.split(',')).length > 0) {
        this.props.router.push('/agent');
      } else {
        this.props.router.push('/students');
      }
    }
  }

  passwordToggle = () => {
    const input = document.getElementById('login-password');
    const unmaskBtn = document.getElementById('unmask');
    if(input.type === 'password') {
      input.type = 'text';
      unmaskBtn.classList.add('active');
    } else {
      input.type = 'password';
      unmaskBtn.classList.remove('active');
    }
  }

  render() {

    const { validationTest, siteLoading, submitLoading } = this.state;
    const { loginEmail, loginPassword } = this.state.formInput;

    return (
      <Layout>
        {
          siteLoading &&
          <SiteLoader/>
        }
        <div className="login-wrapper d-flex justify-content-start align-items-start">
          <ImageWrapper
            query={this.props.query}
          />
          <section className="login-default--wrapper">
            <LoginHeadeing
              query={this.props.query}
            />
            <GoToSignUp
              query={this.props.query}
            />
            <form className="form-login--wrapper" method="post">
              <div className="form-group mb-4">
                <label htmlFor="login-email" className="col-form-label">Email</label>
                <input
                  type="text" autoComplete="email"
                  onChange={this.getFormValue.bind(this)}
                  placeholder="Enter your registered email"
                  className={`form-control ${(validationTest && (validator.email(loginEmail.toLowerCase()) ? '' : 'error')).toString()}`}
                  name="loginEmail"
                  value={loginEmail}
                />

              </div>
              <div className="form-group mb-4">
                <label htmlFor="login-password" className="col-form-label">Password</label>
                <div className="position-relative">
                  <input
                    type="password" autoComplete="current-password"
                    onChange={this.getFormValue.bind(this)}
                    placeholder="Password"
                    id="login-password"
                    className={'form-control password' + (validationTest && (validator.passwordStrength(loginPassword, 'week') ? '' : ' error')).toString()}
                    name="loginPassword"
                    value={loginPassword}/>
                  <div
                    id="unmask"
                    className="unmask"
                    onClick={this.passwordToggle}
                  ><MdRemoveRedEye fontSize="24px" />
                  </div>
                </div>
              </div>
              <Link href="/forget-pass"><a className='forgot-pass'>Forgot Password ?</a></Link>
              <div className="btn-wrapper">
                <button
                  className="btn-solid-large --primary w-100"
                  disabled={submitLoading}
                  onClick={this.handelLogin}>
                  Sign In
                  <div className={`icon-btn--layer ${submitLoading ? 'no-border' : 'primary-border'}`}>
                    {submitLoading ? <div className={`lds-ring`}><div /><div /><div /><div /></div> : <MdArrowForward fontSize="24px" />}
                  </div>
                </button>

              </div>
            </form>
          </section>
        </div>
      </Layout>
    )
  }
}

Login.propTypes = {
  router: PropTypes.object.isRequired,
  query: PropTypes.object
}

export default withRouter(Login);
